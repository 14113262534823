<template>
    <div>
        <Navbar page="Banco de Atividades" link="/cadastros" nameLink="Cadastros" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-12">
                                <label for="name" class="block text-sm font-medium">Nome Banco de atividade</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="agenteTratamento" class="block text-sm font-medium">Agente de tratamento</label>
                                <select required v-model="form.agente" name="agenteTratamento" id="agenteTratamento" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                </select> 
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="finalidade" class="block text-sm font-medium">Finalidade da atividade</label>
                                <select required v-model="form.finalidade" name="finalidade" id="finalidade" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="finalidade in finalidades" :key="finalidade._id" :value="finalidade._id">{{ finalidade.titulo }}</option>
                                </select>                   
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="setor" class="block text-sm font-medium">Setor que desenvolve a atividade</label>
                                <select required v-model="form.setor" name="setor" id="setor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label for="responsavel" class="block text-sm font-medium">Responsável pela atividade</label>
                                <select required v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="tipotitular" class="block text-sm font-medium">Tipo titular dos dados</label>
                                <multiselect v-model="form.tipotitular" :options="tipotitulares" :multiple="true" :close-on-select="false" :taggable="true" label="tipo" trackBy="_id" id="tipotitular" placeholder="selecione os tipos de titulares" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2"/>                
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="baselegal" class="block text-sm font-medium">Base Legal utilizada</label>
                                <select required v-model="form.baselegal" name="baselegal" id="baselegal" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="baselegal in baselegais" :key="baselegal._id" :value="baselegal._id">{{ baselegal.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="legislacoes" class="block text-sm font-medium">Legislações Aplicada</label>
                                <multiselect v-model="form.legislacoes" :options="legislacoes" :multiple="true" :close-on-select="false" label="nome" trackBy="_id" id="legislacoes" placeholder="selecione as opções" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-2" />
                            </div>
                            <div class="col-span-12 md:col-span-12">
                                <label for="dados" class="block text-sm font-medium mb-2">Dados</label>
                                <multiselect v-model="form.dados" :options="dados" :multiple="true" :close-on-select="false" @input="onSelect" :custom-label="customLabel" :taggable="true" label="nome" trackBy="_id" id="dado" placeholder="selecione os dados coletados" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'preatividade',
            finalidades: [],
            agentesTratamento: [],
            setores: [],
            dados: [],
            tipotitulares: [],
            baselegais: [],
            pessoas: [],
            legislacoes: [],
            form: {
                nome: '',
                finalidade: null, 
                tipotitular: [], 
                baselegal: null,
                legislacoes: [],
                setor: null,
                agente: null,
                responsavel: null,
                dados: []
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        customLabel ({ nome, sensivel, critico })  {
            return `${nome}${sensivel ? ' - DADO SENSÍVEL': ''}${critico ? ' - DADO CRÍTICO': ''}`
        },
        onSelect () {
            setTimeout(function() {
                const tags = document.getElementsByClassName('multiselect__tag');
                for(let i = 0; i < tags.length; i++){
                    const tag = tags[i];
                    if(tag.children[0].innerHTML.match('CRÍTICO')){
                        tag.style.backgroundColor = "#f0ad4e";
                    }
                    if(tag.children[0].innerHTML.match('SENSÍVEL')){
                        tag.style.backgroundColor = "#d9534f";
                    }
                }
            }, 500)
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listfinalidades = await this.$http.post(`/v1/finalidades/list`, { all: true});
        this.finalidades = listfinalidades.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const listTitulares = await this.$http.post(`/v1/titulares/list`, { all: true});
        this.tipotitulares = listTitulares.data.data;

        const listDados = await this.$http.post(`/v1/dados/list`, { all: true});
        this.dados = listDados.data.data;

        const listBaselegais = await this.$http.post(`/v1/baselegais/list`, { all: true});
        this.baselegais = listBaselegais.data.data;

        const listLegislacoes = await this.$http.post(`/v1/legislacoes/list`, { all: true});
        this.legislacoes = listLegislacoes.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            this.onSelect();
        }
    },
}
</script>